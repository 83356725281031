<mat-card class="news-info-card" *transloco="let t; read: 'newsData'">
  <form [formGroup]="editForm" (ngSubmit)="onSubmit($event)">
    <mat-card-header class="flex-center">
      <mat-card-title>
        <h1 class="text-2xl sm:text-3xl">
          {{ t('editTitle') }}
        </h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row" style="margin-top: 1.5rem">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('titleLabel') }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ t('titleLabel') }}"
              formControlName="title"
            />
            <mat-error *ngIf="editForm.controls['title'].hasError('required')">
              {{ t('titleLabel') + ' ' + t('errorIsRequired') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('descriptionLabel') }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ t('descriptionLabel') }}"
              formControlName="description"
            />
            <mat-error
              *ngIf="editForm.controls['description'].hasError('required')"
            >
              {{ t('descriptionLabel') + ' ' + t('errorIsRequired') }}
            </mat-error>
            <mat-error
              *ngIf="editForm.controls['description'].hasError('maxLength')"
            >
              {{ t('descriptionLabel') + ' ' + t('errorIsRequired') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('videoUrlLabel') }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ t('videoUrlLabel') }}"
              formControlName="video_url"
            />
            <mat-error
              *ngIf="editForm.controls['video_url'].hasError('invalidUrl')"
            >
              {{ t('videoUrlLabel') + ' ' + t('errorIsRequired') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions
      class="flex-center full-width"
      style="padding-bottom: 1.5rem"
    >
      <div class="row">
        <div class="col flex-center">
          <button
            type="button"
            mat-raised-button
            color="warn"
            routerLink="/news/list"
          >
            {{ t('cancel') }}
          </button>
        </div>
        <div class="col flex-center">
          <button type="submit" mat-raised-button color="accent">
            {{ t('save') }}
          </button>
        </div>
      </div>
    </mat-card-actions>
  </form>
</mat-card>
