import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import Utils from '../services/utils';

@Injectable({
  providedIn: 'root',
})
export class MasjidIdValidationGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const masjidId = route.paramMap.get('masjidId');
    if (masjidId && !Utils.isNumeric(masjidId)) {
      this.router.navigate(['/404']); 
      return false;
    }
    return true;
  }
}