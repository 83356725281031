import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { ComponentStore } from '@ngrx/component-store'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { AppwriteException } from 'appwrite'
import {
    Observable,
    catchError,
    map,
    of,
    switchMap,
    tap,
    throwError,
} from 'rxjs'
import { NewsInterface } from 'src/app/news/interfaces/news.interface'
import {
    NewsStateInterface,
    initialNewsState,
} from 'src/app/news/interfaces/news.state.interface'
import { LogType } from 'src/app/shared/interfaces/log.interface'
import { LogService } from 'src/app/shared/services/log.service'
import { NewsService } from 'src/app/shared/services/news.service'
import { NotificationService } from 'src/app/shared/services/notification.service'
import Utils from 'src/app/shared/services/utils'
import {
    loadMasjidsNews,
    loadMasjidsNewsFailure,
    loadMasjidsNewsSuccess,
    viewNewsDetailsAndNavigate,
} from '../actions/news.actions'

@Injectable({ providedIn: 'root' })
export class NewsStore extends ComponentStore<NewsStateInterface> {
    constructor(
        private actions$: Actions,
        private _store: Store,
        private _logService: LogService,
        private _newsService: NewsService,
        private _notificationService: NotificationService,
        private _router: Router
    ) {
        super(initialNewsState)
    }
    public readonly setNewsList = this.updater(
        (state, news: NewsInterface[]) => ({
            ...state,
            newsList: news,
        })
    )
    readonly getNewsList = this.select((state) => state.newsList)

    viewNewsDetailsAndNavigate$ = this.effect(() =>
        this.actions$.pipe(
            ofType(viewNewsDetailsAndNavigate),
            tap((action) => {
                console.log(
                    'starting effect viewNewsDetailsAndNavigate$ with news ID',
                    action.news_id
                )
                this._router.navigate(['news', 'details', action.news_id])
            })
        )
    )
    loadNewsList$ = this.effect(() =>
        this.actions$.pipe(
            ofType(loadMasjidsNews),
            tap(() => console.log('11111')),
            switchMap(() => {
                this._notificationService.loading()
                return this._newsService
                    .getLatestNews()
                    .pipe(
                        map((news) =>
                            this._store.dispatch(
                                loadMasjidsNewsSuccess({ newsList: news })
                            )
                        )
                    )
            }),
            catchError((error) => of(loadMasjidsNewsFailure(error)))
        )
    )
    loadMasjidNewsListSuccess$ = this.effect(() =>
        this.actions$.pipe(
            ofType(loadMasjidsNewsSuccess),
            tap((action) => {
                console.log('test 123123')
                this.setNewsList(action.newsList)
                this._notificationService.removeLoading()
            })
        )
    )
    loadMasjidNewsListFailure$ = this.effect(() =>
        this.actions$.pipe(
            ofType(loadMasjidsNewsFailure),
            switchMap((action) => {
                console.log('starting effect getMasjidsNewsFailure$')
                this._notificationService.removeLoading()
                console.log('End of effect getMasjidsNewsFailure$', action)
                this._notificationService.notifyFailure(
                    'Error trying to load masjids news.'
                )
                return this.handleErrors(action.error)
            })
        )
    )

    handleErrors(error: Error | AppwriteException): Observable<never> {
        let message: string
        if (error instanceof AppwriteException) {
            message = Utils.formatAppWriteException(error)
        } else message = Utils.formatException(error)
        this._logService.writeLogAsync({
            message: message,
            logType: LogType.Error,
        })
        return throwError(() => error)
    }
}
