<ng-container *ngIf="newsData$ | async as newsData; else loading" >
  <mat-card
    class="masjid-news-details-card"
    *transloco="let t; read: 'newsData'"
  >
    <img
      matCardImage
      [src]="newsData.imageUrl"
      [alt]="'Image for ' + newsData.title"
    />
    <mat-card-header>
      <mat-card-title>
        <h1>{{ newsData.title }}</h1>
      </mat-card-title>
      <mat-card-subtitle>
        {{ t('publishDate') }}
        {{ newsData.created_date?.toLocaleString() }}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <p>{{ newsData.description }}</p>
      </div>
      <!-- Video Section -->
      <div *ngIf="newsData.videoUrl">
        <video
          controls
          [src]="newsData.videoUrl"
          width="100%"
          height="auto"
          poster="{{ newsData.videoUrl || '' }}"
        >
          Your browser does not support the video tag.
        </video>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>

<ng-template #loading>
  <p>Loading masjids news...</p>
</ng-template>
