<ng-container *ngIf="newsList | async; else loading">
  <app-news-card
    *ngFor="let news of newsList | async"
    [newsData]="news"
  ></app-news-card>
</ng-container>

<ng-template #loading>
  <p>loading masjids news...</p>
</ng-template>
