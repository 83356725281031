import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { VideoInterface, VideoType } from 'src/app/shared/interfaces/video.interface';
import Utils from '../../../services/utils';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatIconButton } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-video-upload',
    templateUrl: './video-upload.component.html',
    styleUrls: ['./video-upload.component.scss'],
    standalone: true,
    imports: [
        MatInputModule,
        MatListModule,
        MatToolbarModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        CommonModule,
        ReactiveFormsModule,
        TranslocoModule,
    ]
})
export class VideoUploadComponent implements OnInit {
    @Output() sendVideosToParentForm: EventEmitter<VideoInterface[]> = new EventEmitter();
    videosToUpload: VideoInterface[] = [];
    uploadForm: FormGroup;
    errorMessage: string = '';
    inputMessage: string = 'Select Video(s)';

    @Input() bucketId: string;
    @Input() fileLimit: number = 1;
    @Input() videoType: VideoType;
    @Input() isRequired: boolean = false;
    @Input() maxFileSizeMB: number = 30;
    @Input() acceptedFormats: string = 'video/mp4, video/webm, video/ogg'; // Updated to reflect common formats

    constructor(private _fb: FormBuilder) {}

    ngOnInit(): void {
        this.uploadForm = this._fb.group({
            uploadVideos: [null, this.isRequired ? Validators.required : []],
        });
    }

    selectedVideos(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (!input?.files?.length) {
            this.errorMessage = 'No files selected.';
            return;
        }

        this.videosToUpload = [];
        this.errorMessage = '';
        const files = input.files;

        if (files.length > this.fileLimit) {
            this.errorMessage = `You can upload up to ${this.fileLimit} video(s).`;
            return;
        }

        Array.from(files).forEach((file) => {
            if (!this.isAcceptedFormat(file.type)) {
                this.errorMessage = `Invalid file format: ${file.name}. Accepted formats: ${this.acceptedFormats}`;
                return;
            }

            if (Utils.convertBytesToMegaByte(file.size) > this.maxFileSizeMB) {
                this.errorMessage = `File ${file.name} exceeds ${this.maxFileSizeMB} MB.`;
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                const video: VideoInterface = {
                    id: Utils.generateUniqueId(),
                    bucketId: this.bucketId,
                    name: file.name,
                    videoFile: file,
                    dataUrl: reader.result as string,
                    videoType: this.videoType,
                };
                this.videosToUpload.push(video);
                this.sendVideosToParentForm.emit([...this.videosToUpload]); // Emit after each successful file read
            };
            reader.onerror = () => {
                this.errorMessage = `Failed to load video: ${file.name}`;
            };
            reader.readAsDataURL(file);
        });
    }

    private isAcceptedFormat(fileType: string): boolean {
        return this.acceptedFormats.split(',').some((format) => format.trim() === fileType);
    }
}
