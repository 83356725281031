import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { MatCardModule } from '@angular/material/card'
import { Store } from '@ngrx/store'
import { NewsInterface } from 'src/app/news/interfaces/news.interface'
import { viewNewsDetailsAndNavigate } from 'src/app/news/store/actions/news.actions'

@Component({
    selector: 'app-news-card',
    templateUrl: './news-card.component.html',
    styleUrl: './news-card.component.scss',
    standalone: true,
    imports: [CommonModule, MatCardModule],
})
export class NewsCardComponent {
    constructor(private _store: Store) {}
    @Input() newsData: NewsInterface
    onViewDetails() {
        // Dispatch an action to view masjid details
        this._store.dispatch(
            viewNewsDetailsAndNavigate({ news_id: this.newsData.news_id!! })
        )
    }
}
