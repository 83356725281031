import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { NewsInterface } from 'src/app/news/interfaces/news.interface'
import {
    PhotoInterface,
    PhotoType,
} from 'src/app/shared/interfaces/Image.interface'
import {
    VideoInterface,
    VideoType,
} from 'src/app/shared/interfaces/video.interface'
import { NewsService } from 'src/app/shared/services/news.service'
import { NotificationService } from 'src/app/shared/services/notification.service'
import { environment } from 'src/environments/environment'
import { catchError, forkJoin, Observable, tap, throwError } from 'rxjs'
import { FileStorageService } from 'src/app/shared/services/file-storage.service'
import { Component, Input, OnInit } from '@angular/core'
import {
    FormGroup,
    FormBuilder,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { tapResponse } from '@ngrx/operators'
import { CommonModule } from '@angular/common'
import {
    MatError,
    MatFormFieldModule,
    MatLabel,
} from '@angular/material/form-field'
import { MatCardModule } from '@angular/material/card'
import { PhotoUploadComponent } from 'src/app/shared/components/file-upload/photo-upload/photo-upload.component'
import { VideoUploadComponent } from 'src/app/shared/components/file-upload/video-upload/video-upload.component'
import { Models } from 'appwrite'
import { TranslocoModule } from '@ngneat/transloco'
import { MatInputModule } from '@angular/material/input'
import { MatButtonModule } from '@angular/material/button'
import Utils from 'src/app/shared/services/utils'

@UntilDestroy()
@Component({
    selector: 'app-create-news',
    templateUrl: './create-news.component.html',
    styleUrls: ['./create-news.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatError,
        MatLabel,
        MatCardModule,
        PhotoUploadComponent,
        VideoUploadComponent,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        TranslocoModule,
    ],
})
export class CreateNewsComponent implements OnInit {
    bucketPhotoId: string = environment.news_images_bucket
    bucketVideoId: string = environment.news_videos_bucket
    previewImageUrl: string | null = null
    previewVideoUrl: string | null = null
    createForm: FormGroup
    errorMessage: string = ''
    successMessage: string = ''

    selectedPhoto: PhotoInterface | null = null
    selectedVideo: VideoInterface | null = null
    videoType: VideoType = VideoType.News
    photoType: PhotoType = PhotoType.News
    @Input() masjidId: string

    constructor(
        private _newsService: NewsService,
        private _fb: FormBuilder,
        private _notification: NotificationService,
        private _activatedRoute: ActivatedRoute,
        private _storageService: FileStorageService
    ) {}

    ngOnInit(): void {
        const masjidIdFromRoute =
            this._activatedRoute.snapshot.paramMap.get('masjidId')
        this.masjidId = masjidIdFromRoute || ''
        this.createForm = this._fb.group({
            title: ['', Validators.required],
            description: [
                '',
                [Validators.required, Validators.maxLength(2000)],
            ],
            masjid_id: [this.masjidId, Validators.required],
            news_id: [Utils.generateUniqueId(), Validators.required],
            image_id: [''],
            video_id: [''],
        })
        console.log('news form info:', this.createForm.value)
    }
    // Capture selected video
    onVideoSelected(videos: VideoInterface[]): void {
        if (videos.length > 0) {
            this.selectedVideo = videos[0]
            console.log('Selected Video:', this.selectedVideo)
        } else {
            this.selectedVideo = null
        }
    }

    // Capture selected photo
    onPhotoSelected(photos: PhotoInterface[]): void {
        if (photos.length > 0) {
            this.selectedPhoto = photos[0]
            console.log('Selected Photo:', this.selectedPhoto)
        } else {
            this.selectedPhoto = null
        }
    }

    private uploadNewsPhoto(photo: PhotoInterface): Observable<Models.File> {
        return this._storageService
            .uploadFile(photo.photo, photo.id!, photo.bucketId)
            .pipe(
                tapResponse(
                    (photoId) =>
                        console.log('Image uploaded successfully', photoId),
                    (error) => console.error('Image upload failed', error)
                ),
                untilDestroyed(this)
            )
    }

    private uploadNewsVideo(video: VideoInterface): Observable<Models.File> {
        return this._storageService
            .uploadFile(video.videoFile!, video.id!, video.bucketId)
            .pipe(
                tapResponse(
                    (videoId) =>
                        console.log('Video uploaded successfully', videoId),
                    (error) => console.error('Video upload failed', error)
                ),
                untilDestroyed(this)
            )
    }

    onSubmit(event: Event): void {
        console.info('form submit data:', this.createForm.value)
        if (this.createForm.invalid) {
            this.createForm.markAllAsTouched()
            return
        }
        
        const formData: NewsInterface = this.createForm.value as NewsInterface
        
        let photoUpload$: Observable<Models.File> | null = null
        let videoUpload$: Observable<Models.File> | null = null

        // Check if a photo needs to be uploaded
        if (this.selectedPhoto) {
            photoUpload$ = this.uploadNewsPhoto(this.selectedPhoto)
        }

        // Check if a video needs to be uploaded
        if (this.selectedVideo) {
            videoUpload$ = this.uploadNewsVideo(this.selectedVideo)
        }

        // Execute uploads (if needed) and then save data
        this.handleUploads(photoUpload$, videoUpload$, formData)
    }

    private handleUploads(
        photoUpload$: Observable<Models.File> | null,
        videoUpload$: Observable<Models.File> | null,
        formData: NewsInterface
    ): void {
        const uploadTasks = []

        if (photoUpload$) {
            uploadTasks.push(
                photoUpload$.pipe(
                    tapResponse(
                        (photoResponse) => {
                            console.log(
                                'Photo uploaded successfully:',
                                photoResponse
                            )
                            formData.image_id = photoResponse.$id
                        },
                        (error) => {
                            console.error('Photo upload error:', error)
                            this._notification.notifyFailure(
                                'Failed to upload photo.'
                            )
                        }
                    ),
                    catchError((error) => {
                        return throwError(
                            () => new Error('Photo upload failed')
                        )
                    })
                )
            )
        }

        if (videoUpload$) {
            uploadTasks.push(
                videoUpload$.pipe(
                    tapResponse(
                        (videoResponse) => {
                            console.log(
                                'Video uploaded successfully:',
                                videoResponse
                            )
                            formData.video_id = videoResponse.$id
                        },
                        (error) => {
                            console.error('Video upload error:', error)
                            this._notification.notifyFailure(
                                'Failed to upload video.'
                            )
                        }
                    ),
                    catchError((error) => {
                        return throwError(
                            () => new Error('Video upload failed')
                        )
                    })
                )
            )
        }

        if (uploadTasks.length > 0) {
            forkJoin(uploadTasks)
                .pipe(
                    tap(() =>
                        console.log(
                            'All uploads completed. FormData:',
                            formData
                        )
                    )
                )
                .subscribe({
                    complete: () => {
                        this.saveNewsData(formData) // Ensure this runs after all updates
                    },
                    error: () => {
                        console.error('Upload tasks failed.')
                        this._notification.notifyFailure(
                            'One or more uploads failed.'
                        )
                    },
                })
        } else {
            this.saveNewsData(formData)
        }
    }

    private saveNewsData(formData: NewsInterface): void {
        this._newsService.createMasjidNews(formData).subscribe({
            next: () => {
                this._notification.notifySuccess(
                    'News data has been saved successfully.'
                )
            },
            error: (error) => {
                console.error('Error saving news data:', error)
                this._notification.notifyFailure(
                    'An error occurred while saving news data. Please try again.'
                )
            },
        })
    }
}
