import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import Utils from '../services/utils'; 

@Injectable({
  providedIn: 'root',
})
export class NewsIdValidationGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const newsId = route.paramMap.get('newsId');
    if (newsId && !Utils.isNumeric(newsId)) {
      this.router.navigate(['/404']);
      return false;
    }
    return true;
  }
}