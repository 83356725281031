import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { MatCardModule } from '@angular/material/card'
import { ActivatedRoute } from '@angular/router'
import { TranslocoModule } from '@ngneat/transloco'
import {
    Observable,
    catchError,
    map,
    of,
    switchMap,
    tap,
    throwError,
} from 'rxjs'
import { NewsInterface } from 'src/app/news/interfaces/news.interface'
import { NewsStore } from 'src/app/news/store/states/news.state'
import { LogType } from 'src/app/shared/interfaces/log.interface'
import { LogService } from 'src/app/shared/services/log.service'
import { NewsService } from 'src/app/shared/services/news.service'
import Utils from 'src/app/shared/services/utils'

@Component({
    selector: 'app-news-details',
    templateUrl: './news-details.component.html',
    styleUrl: './news-details.component.scss',
    standalone: true,
    imports: [CommonModule, MatCardModule, TranslocoModule],
})
export class NewsDetailsComponent implements OnInit {
    newsData$: Observable<NewsInterface | null>

    constructor(
        private _newsStore: NewsStore,
        private _activatedRoute: ActivatedRoute,
        private _newsService: NewsService,
        private _logService: LogService
    ) {}

    ngOnInit(): void {
        const id = this._activatedRoute.snapshot.paramMap.get('newsId')
        if (id) {
            this.newsData$ = this.findNewsDataById(id).pipe(
                tap((res) => {
                    console.log('data was found in the store', res)
                })
            )
        } else {
            throw new Error('Id not found')
        }
    }

    findNewsDataById(id: string): Observable<NewsInterface | null> {
        return this.findNewsDataByIdInStore(id).pipe(
            switchMap((news) => {
                if (news !== null) {
                    console.log('masjid news was found in store?', news)
                    return of(news)
                } else {
                    return this._newsService.getMasjidNewsById(id).pipe(
                        catchError((error) => {
                            this._logService.writeLogAsync({
                                logType: LogType.Error,
                                message: Utils.formatException(error),
                            })
                            console.error(
                                'Error fetching news from backend',
                                error
                            )
                            return throwError(
                                () =>
                                    new Error(
                                        'News not found both in store and backend'
                                    )
                            )
                        })
                    )
                }
            })
        )
    }

    findNewsDataByIdInStore(id: string): Observable<NewsInterface | null> {
        return this._newsStore.getNewsList.pipe(
            map((list) => list.find((item) => item.news_id === id) || null)
        )
    }
}
