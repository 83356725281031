import { Routes } from '@angular/router'
import { AuthenticatedGuard } from '../shared/guards/authenticated.guard'
import { CreateNewsComponent } from './components/create-news/create-news.component'
import { EditNewsComponent } from './components/edit-news/edit-news.component'
import { ListNewsComponent } from './components/list-news/list-news.component'
import { NewsDetailsComponent } from './components/news-details/news-details.component'
import { MasjidIdValidationGuard } from '../shared/guards/masjidIdValidation.guard'
import { NewsIdValidationGuard } from '../shared/guards/newsIdValidation.guard'

export const newsRoutes: Routes = [
    {
        // if '/news' only called redirects to /home
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
    },
    {
        path: '',
        children: [
            {
                path: 'create/:masjidId',
                component: CreateNewsComponent,
                canActivate: [AuthenticatedGuard, MasjidIdValidationGuard],
            },
            {
                path: 'details/:newsId',
                component: NewsDetailsComponent,
                canActivate: [NewsIdValidationGuard],
            },
            {
                path: 'list',
                component: ListNewsComponent,
            },
            {
                path: 'edit/:newsId',
                component: EditNewsComponent,
                canActivate: [AuthenticatedGuard, NewsIdValidationGuard],
            },
        ],
    },
]
