import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, of, tap } from 'rxjs'
import { loadMasjidsNews } from 'src/app/news/store/actions/news.actions'
import { NewsInterface } from '../../interfaces/news.interface'
import { NewsStore } from '../../store/states/news.state'
import { CommonModule } from '@angular/common'
import { NewsCardComponent } from './news-card/news-card.component'

@Component({
    selector: 'app-list-news',
    templateUrl: './list-news.component.html',
    styleUrl: './list-news.component.scss',
    standalone: true,
    imports: [CommonModule, NewsCardComponent],
})
export class ListNewsComponent implements OnInit {
    constructor(private _store: Store, private _newsStore: NewsStore) {}
    newsList: Observable<NewsInterface[]> = of([])
    ngOnInit(): void {
        console.log('init list news component')
        this._store.dispatch(loadMasjidsNews())
        this.newsList = this._newsStore.getNewsList.pipe(
            tap(() => console.log('news loaded'))
        )
    }
}
