<mat-card class="video-upload-card" *transloco="let t; read: 'videoUpload'">
  <mat-card-header>
    <mat-card-title>{{ t('title') }}</mat-card-title>
    <mat-card-subtitle>{{ t('subtitle') }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <div class="row">
      <div class="button-container">
        <button mat-stroked-button color="primary" (click)="videoInput.click()" type="button">
          <mat-icon>attach_file</mat-icon>
          {{ t('selectVideos') }}
        </button>
        <input
          type="file"
          #videoInput
          accept="{{ acceptedFormats }}"
          (change)="selectedVideos($event)"
          hidden
        />
        <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
      </div>
    </div>
    <div class="preview-container" *ngIf="videosToUpload.length > 0">
      <div class="preview-item" *ngFor="let file of videosToUpload">
        <ng-container *ngIf="file.videoFile.type.startsWith('video');">
          <video
            [src]="file.dataUrl"
            controls
            class="preview-video"
          ></video>
        </ng-container>
      </div>
    </div>
  </mat-card-content>
</mat-card>
