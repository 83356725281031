<div class="flex-center">
  <mat-card class="news-card" *ngIf="newsData" (click)="onViewDetails()">
    <mat-card-header>
      <mat-card-title>
        <h2>{{ newsData.title }}</h2>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <p>{{ newsData.description }}</p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
