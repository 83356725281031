import { FileInterface } from "src/app/shared/interfaces/file.Interface";

export interface VideoInterface extends FileInterface {
    dataUrl: string;
    videoFile: File;
    videoType: VideoType;
}

export enum VideoType {
    MasjidIntro = 'masjid_intro',
    Event = 'event',
    Khutbah = 'khutbah',
    News = 'news',
    Other = 'other',
}