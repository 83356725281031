import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    ValidatorFn,
    Validators,
} from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import {
    MatError,
    MatFormFieldModule,
    MatLabel,
} from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { tapResponse } from '@ngrx/operators'
import { AppwriteException } from 'appwrite'
import { tap } from 'rxjs'
import { NewsInterface } from 'src/app/news/interfaces/news.interface'
import { LogType } from 'src/app/shared/interfaces/log.interface'
import { LogService } from 'src/app/shared/services/log.service'
import { NewsService } from 'src/app/shared/services/news.service'
import { NotificationService } from 'src/app/shared/services/notification.service'

@UntilDestroy()
@Component({
    selector: 'app-edit-news',
    templateUrl: './edit-news.component.html',
    styleUrl: './edit-news.component.scss',
    standalone: true,
    imports: [
        CommonModule,
        MatCardModule,
        MatFormFieldModule,
        MatLabel,
        MatError,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        TranslocoModule
    ],
})
export class EditNewsComponent implements OnInit {
    editForm: FormGroup
    newsData: NewsInterface
    errorMessage: string = ''
    successMessage: string = ''

    constructor(
        private _logService: LogService,
        private _newsService: NewsService,
        private _fb: FormBuilder,
        private _notification: NotificationService,
        private _activatedRoute: ActivatedRoute,
        private _route: Router
    ) {}

    ngOnInit(): void {
        this.editForm = this._fb.group({
            title: ['', Validators.required],
            description: [
                '',
                [Validators.required, Validators.maxLength(2000)],
            ],
            video_url: ['', this.urlValidator()],
            masjid_id: ['', Validators.required],
            news_id: ['', Validators.required],
            image_id: [''],
            video_id: [''],
            created_date: [''],
            updated_date: [''],
        })

        const newIdFromRoute =
            this._activatedRoute.snapshot.paramMap.get('newsId')

        if (newIdFromRoute) {
            this._logService.writeLogAsync({
                logType: LogType.Info,
                message: 'Edit news component started!',
            })
            this._newsService
                .getMasjidNewsById(newIdFromRoute!!)
                .pipe(
                    tapResponse(
                        (news) => {
                            this.newsData = news
                            return news
                        },
                        (error: any) => {
                            this._notification.notifyFailure(
                                'masjid news id was not found!'
                            )
                            setTimeout(() => {
                                this._route.navigate(['/pageNotFound'])
                            }, 3000)
                        }
                    ),
                    tap(() => {
                        this.editForm.patchValue({
                            title: this.newsData.title,
                            description: this.newsData.description,
                            masjid_id: this.newsData.masjid_id,
                            news_id: this.newsData.news_id,
                            image_id: this.newsData.image_id,
                            video_id: this.newsData.video_id,
                            created_date: this.newsData.created_date,
                            updated_date: this.newsData.updated_date,
                        })
                        console.log('news data:', this.newsData)
                    }),
                    untilDestroyed(this)
                )
                .subscribe()
        } else {
            console.log(
                'news id is not available redirecting to home page.',
                newIdFromRoute
            )
            this._route.navigate(['/pageNotFound'])
        }
    }
    onSubmit(event: Event): void {
        let data: NewsInterface = this.editForm.value as NewsInterface
        console.log('data', data)
        if (this.editForm.valid) {
            this._newsService
                .updateNewsData(data)
                .pipe(
                    tapResponse(
                        (document) => {
                            this._notification.notifySuccess(
                                'Masjid news data has been saved successfully.'
                            )
                            setTimeout(() => {
                                this._route.navigateByUrl('/news/list')
                            }, 3000)
                        },
                        (error: Error | AppwriteException) => {
                            this._notification.notifyFailure(
                                'An error has occurred while saving the news masjids data.'
                            )
                            console.error(error)
                        }
                    ),
                    untilDestroyed(this)
                )
                .subscribe()
        } else {
            this.editForm.markAsTouched()
        }
    }

    urlValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null // Empty string is considered valid
            }
            const regex =
                /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
            const isValid = regex.test(control.value)
            console.log('URL is Valid: ', isValid)
            return isValid ? null : { invalidUrl: { value: control.value } }
        }
    }
}
